import { State } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { formatPercent } from "@helpers";
import { RootState } from "@store/types";
import { HtmlElementId } from "@/types/element";
import { statisticModule } from "@store/namespaces";
import { StatisticGetters } from "@store/modules/statistic/types";

@Component
export default class HeaderStatDeclines extends Vue {
  @State
  private readonly enabledNewYearDesign!: RootState["enabledNewYearDesign"];
  @statisticModule.Getter
  private readonly statisticDeclines!: StatisticGetters["statisticDeclines"];

  private get htmlElementId() {
    return {
      headerStatDecline: HtmlElementId.headerStatDecline,
    };
  }

  private get declineRateStatus() {
    if (this.statisticDeclines.declineRate >= 30) {
      return "bad";
    }

    if (this.statisticDeclines.declineRate >= 15) {
      return "normal";
    }

    return "great";
  }

  private get items() {
    return [
      {
        text: "Decline rate by sum",
        value: formatPercent({
          value: this.statisticDeclines.declineRate,
          isDecimal: false,
        }),
      },
      {
        text: "Decline rate by count",
        value: formatPercent({
          value: this.statisticDeclines.declineRateByCount,
          isDecimal: false,
        }),
      },
    ];
  }
}
