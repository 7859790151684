var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('aside',{class:[
    'header-stat-decline d-flex overflow-hidden text-no-wrap',
    `header-stat-decline_status_${_vm.declineRateStatus}`,
    {
      'header-stat-decline_new-year-design': _vm.enabledNewYearDesign,
    },
  ],attrs:{"id":_vm.htmlElementId.headerStatDecline}},[_vm._m(0),_c('div',{staticClass:"header-stat-decline__items flex-grow-1 flex-shrink-0 pl-2 pr-4"},_vm._l((_vm.items),function(item,itemIndex){return _c('p',{key:itemIndex,staticClass:"header-stat-decline__item d-flex align-center mb-0"},[_vm._v(" "+_vm._s(item.text)+" "),_c('span',{staticClass:"d-block font-weight-600 text-right flex-grow-1 ml-4 header-stat-decline__item-value"},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header-stat-decline__label d-flex align-center flex-shrink-0 px-4 pr-2 primary white--text text-uppercase"},[_c('span',{staticClass:"flex-shrink-0 font-weight-bold header-stat-decline__label-text"},[_vm._v(" Last"),_c('br'),_vm._v("days ")]),_c('b',{staticClass:"header-stat-decline__label-value ml-1 flex-shrink-0"},[_vm._v(" 7 ")])])
}]

export { render, staticRenderFns }